import styled from "styled-components";
import { Link, useLocation, useParams, useHistory } from "react-router-dom";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Container, Col, Form } from "react-bootstrap";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Header from "../../components/Header";
//api

// import MyLaserApi from "../../../api/MyLaserApi";

import DataTable from "react-data-table-component";

import { useSelector } from "react-redux";

import Swal from "sweetalert2";

import Products from "../../resources/Products";

import { generateTreamentAndSessions } from "../../services/QGeneration";

import MyLaserApi from "../../api/MyLaserApi";

import ButtonLoader from "../../components/ButtonLoader";

import { toast } from "react-toastify";

const HeaderPatient = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
    width: 100%;
    background-color: #b4cfd7;
    border-radius: 10px;
    margin-bottom: 10px;
`;

const Text = styled.span`
    font-family: CenturyGothicRegular;
    color: #102935;
    font-size: 13px;
    &.bold {
        font-family: CenturyGothicBold;
        color: #f0b594;
        font-size: 15px;
    }
    &.blue {
        color: #102935;
    }
    &.gray {
        color: #9fa9ae;
    }
    &.underline {
        text-decoration-line: underline;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin-top: 20px;
    &.spaced-items {
        justify-content: space-between;
    }
    &.custom-width {
        width: 50%;
    }
    &.center {
        align-items: center;
    }
`;

const ContainerTreatment = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: transparent;
    margin-top: 20px;
    width: 96%;
`;

function PatientTreatmentLaser() {
    let params = useParams();

    const user = useSelector((state) => state.user);

    // const [products, setProducts] = useState(Products);

    const [promotions, setPromotions] = useState([]);

    let reload = () => {
        MyLaserApi.call({
            method: "GET",
            url: `/agencies/clients/${params.id}/promotions`,
        }).then((res) => {
            setPromotions(res.data);
        });
    };

    useEffect(() => {
        reload();
    }, []);

    return (
        <Container fluid className="App">
            <Row>
                <Col
                    md={12}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Header type="client" />

                    <ContainerTreatment>
                        <Row
                            className="spaced-items center"
                            style={{ marginBottom: "20px" }}
                        >
                            <Text className="bold blue">
                                Promotions & Bon d'achat{" "}
                                <Text className="bold"></Text>
                            </Text>

                            <Row
                                className="custom-width center"
                                style={{
                                    alignItems: "flex-end",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <div
                                    style={{
                                        textDecorationLine: "none",
                                        width: "40%",
                                        marginRight: "10px",
                                    }}
                                >
                                    <Button
                                        style={{ width: "100%" }}
                                        className="blue"
                                        onClick={() => {
                                            Swal.fire({
                                                title: "Ajouter une promotion ou un bon d'achat",
                                                html: `
                                                    <input 
                                                        id="promoCode" 
                                                        class="swal2-input" 
                                                        placeholder="Code promo ou bon d'achat"
                                                    >
                                                `,
                                                showCancelButton: true,
                                                confirmButtonText: "Ajouter",
                                                cancelButtonText: "Annuler",
                                                preConfirm: () => {
                                                    const code =
                                                        document.getElementById(
                                                            "promoCode"
                                                        ).value;
                                                    if (!code) {
                                                        Swal.showValidationMessage(
                                                            "Veuillez saisir un code"
                                                        );
                                                        return false;
                                                    }
                                                    return code;
                                                },
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    MyLaserApi.call({
                                                        method: "POST",
                                                        url: `/agencies/clients/${params.id}/promotions`,
                                                        body: {
                                                            code: result.value,
                                                        },
                                                    }).then((result) => {
                                                        reload();
                                                    });
                                                }
                                            });
                                        }}
                                    >
                                        Ajouter une promotion ou un bon d'achat
                                    </Button>
                                </div>
                            </Row>
                        </Row>

                        <div
                            style={{
                                borderRadius: "10px",
                                width: "100%",
                                marginBottom: "25px",
                            }}
                        >
                            <DataTable
                                style={{ marginTop: "10px", top: "10px" }}
                                noDataComponent={
                                    <div
                                        style={{
                                            paddingTop: 10,
                                            paddingBottom: 10,
                                        }}
                                    >
                                        Il n'y a aucune promotion ou bon d'achat
                                    </div>
                                }
                                pagination={false}
                                data={promotions}
                                columns={[
                                    {
                                        name: "Code",
                                        selector: "code",
                                        sortable: true,
                                        cell: (row) => {
                                            return <Text>{row.code}</Text>;
                                        },
                                    },
                                    {
                                        name: "Type",
                                        selector: "type",
                                        sortable: true,
                                        cell: (row) => {
                                            return (
                                                <Text>
                                                    {(() => {
                                                        switch (row.type) {
                                                            case "referral":
                                                                return "Parrainage";
                                                            case "referral-back":
                                                                return "Parrain";
                                                            case "gift-card":
                                                                return "Bon d'achat";
                                                            default:
                                                                return "Inconnu";
                                                        }
                                                    })()}
                                                    <br />
                                                    {row.more}
                                                </Text>
                                            );
                                        },
                                    },

                                    {
                                        name: "Utilisé",
                                        selector: "used",
                                        sortable: true,
                                        cell: (row) => {
                                            return (
                                                <Text>
                                                    {row.agency_client_treatment_discount_id
                                                        ? "Oui"
                                                        : "Non"}
                                                </Text>
                                            );
                                        },
                                    },

                                    {
                                        name: "Promotion",
                                        selector: "promotion",
                                        sortable: true,
                                        cell: (row) => {
                                            return (
                                                <Text>
                                                    {(() => {
                                                        if (row.discounts) {
                                                            const discounts =
                                                                JSON.parse(
                                                                    row.discounts
                                                                );

                                                            if (
                                                                discounts.length >
                                                                0
                                                            ) {
                                                                return discounts
                                                                    .map(
                                                                        (
                                                                            discount
                                                                        ) => {
                                                                            return (
                                                                                (discount.type_human ===
                                                                                "all"
                                                                                    ? "Bon d'achat"
                                                                                    : discount.type_human) +
                                                                                " " +
                                                                                discount.amount +
                                                                                " " +
                                                                                global.currencySymbol
                                                                            );
                                                                        }
                                                                    )
                                                                    .join(
                                                                        " OU "
                                                                    );
                                                            }
                                                        }
                                                        return "Inconnu";
                                                    })()}
                                                </Text>
                                            );
                                        },
                                    },
                                    {
                                        name: "Actions",
                                        selector: "actions",
                                        sortable: true,
                                        cell: (row) => {
                                            if (
                                                row.type === "gift-card" &&
                                                !row.agency_client_treatment_discount_id
                                            ) {
                                                return (
                                                    <Button
                                                        className="reverse"
                                                        style={{
                                                            width: "100%",
                                                            marginBottom:
                                                                "10px",
                                                            marginTop: "10px",
                                                        }}
                                                        onClick={() => {
                                                            //transferer vers un autre compte (input avec le numéro de dossier)
                                                            Swal.fire({
                                                                title: "Transférer le bon d'achat",
                                                                text: "Veuillez saisir le numéro de dossier du patient à qui vous voulez transférer le bon d'achat",
                                                                input: "text",
                                                                inputPlaceholder:
                                                                    "Numéro de dossier",
                                                                inputAttributes:
                                                                    {
                                                                        autocapitalize:
                                                                            "off",
                                                                    },
                                                                showCancelButton: true,
                                                                confirmButtonText:
                                                                    "Transférer",
                                                                preConfirm: (
                                                                    dossier
                                                                ) => {
                                                                    //dossier should be a number
                                                                    if (
                                                                        !dossier ||
                                                                        dossier ===
                                                                            "" ||
                                                                        isNaN(
                                                                            dossier
                                                                        )
                                                                    ) {
                                                                        Swal.showValidationMessage(
                                                                            "Veuillez saisir un numéro de dossier"
                                                                        );
                                                                        return false;
                                                                    }
                                                                    return dossier;
                                                                },
                                                            }).then(
                                                                (result) => {
                                                                    MyLaserApi.getClient(
                                                                        result.value
                                                                    ).then(
                                                                        (
                                                                            client
                                                                        ) => {
                                                                            if (
                                                                                client &&
                                                                                client.data
                                                                            ) {
                                                                                Swal.fire(
                                                                                    {
                                                                                        title: "Confirmation",
                                                                                        text: `Voulez-vous vraiment transférer le bon d'achat à ${client.data.firstname} ${client.data.lastname} ?`,
                                                                                        icon: "question",
                                                                                        showCancelButton: true,
                                                                                        confirmButtonText:
                                                                                            "Transférer",
                                                                                        cancelButtonText:
                                                                                            "Annuler",
                                                                                    }
                                                                                ).then(
                                                                                    (
                                                                                        result
                                                                                    ) => {
                                                                                        if (
                                                                                            result.isConfirmed
                                                                                        ) {
                                                                                            MyLaserApi.call(
                                                                                                {
                                                                                                    method: "POST",
                                                                                                    url: `/agencies/clients/${params.id}/promotions/${row.id}/transfer`,
                                                                                                    body: {
                                                                                                        client_id:
                                                                                                            client
                                                                                                                .data
                                                                                                                .id,
                                                                                                    },
                                                                                                }
                                                                                            ).then(
                                                                                                () => {
                                                                                                    reload();
                                                                                                }
                                                                                            );
                                                                                        }
                                                                                    }
                                                                                );
                                                                            }
                                                                        }
                                                                    );
                                                                }
                                                            );
                                                        }}
                                                    >
                                                        Transférer
                                                    </Button>
                                                );
                                            } else {
                                                return (
                                                    <Text>Aucune action</Text>
                                                );
                                            }
                                        },
                                    },
                                ]}
                                onRowClicked={(row) => {
                                    // if (specialNoteMode === true) {
                                    //     if (row.el.special_note_status == 0) {
                                    //         setModalShow(true);
                                    //         setSession(row.el);
                                    //     } else {
                                    //         history.push(
                                    //             `/patients/${params.id}/treatments/laser/notes/${row.el.id}/details`
                                    //         );
                                    //     }
                                    // }
                                }}
                                paginationComponentOptions={{
                                    rowsPerPageText: "Elements par page",
                                    rangeSeparatorText: "de",
                                    selectAllRowsItem: false,
                                    selectAllRowsItemText: "Suivant",
                                }}
                            />
                        </div>
                    </ContainerTreatment>
                </Col>
            </Row>
        </Container>
    );
}

export default PatientTreatmentLaser;
