import styled from "styled-components";
import { Container, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Loader from "react-js-loader";
import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";

//components
import Input from "../../components/Input";
import Table from "../../components/Table";
import Header from "../../components/Header";
import SideBar from "../../components/SideBar";
import Modal from "../../components/Modal";

//api
import MyLaserApi from "../../api/MyLaserApi";
import DatePicker from "react-datepicker";
import Button from "../../components/Button";

import Select from "react-select";

import DataTable from "react-data-table-component";

import moment from "moment";

const Text = styled.span`
    margin-left: 10px;
    font-family: CenturyGothicRegular;
    color: #102935;
    font-size: 12px;
    &.bold {
        font-family: CenturyGothicBold;
    }
    &.gray {
        opacity: 0.3;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    &.spaced-items {
        justify-content: space-between;
    }
`;

const Block = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    background-color: #fff;
    margin-top: 20px;
    border-radius: 10px;
    width: 100%;
    min-height: 70vh;
`;
const ContainerPatients = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: transparent;
    width: 96%;
    align-self: center;
`;
const HeaderPatient = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
    width: 100%;
    background-color: #b4cfd7;
    border-radius: 10px;
    margin-bottom: 10px;
`;

const Label = styled.span`
    font-family: CenturyGothicRegular;
    color: #102935;
    font-size: 13px;
    margin-bottom: 10px;
`;

const Form = ({ informations, onInformationsChange }) => (
    <div style={{ width: "100%" }}>
        <Row className="spaced-items">
            <Input
                noIcon
                className="half"
                placeholder="Nom"
                label="Nom"
                value={informations.lastname}
                onChange={(e) =>
                    onInformationsChange({ lastname: e.target.value })
                }
            />
            <Input
                noIcon
                className="half"
                placeholder="Prénom"
                label="Prénom"
                value={informations.firstname}
                onChange={(e) =>
                    onInformationsChange({ firstname: e.target.value })
                }
            />
        </Row>
        <Row className="spaced-items">
            <div
                style={{
                    display: "flex",
                    alignItems: "flex-start",
                    width: "49%",
                    flexDirection: "column",
                }}
            >
                {/* <Label style={{ marginBottom: 10 }}>Date de naissance {informations.birthdate} </Label> */}
                <Input
                    noIcon
                    className="with-label"
                    label="Date de naissance"
                    value={informations.birthdate}
                    onChange={(e) =>
                        onInformationsChange({ birthdate: e.target.value })
                    }
                    style={{ width: "100%" }}
                    type="date"
                />
                {/* <DatePicker 
                    selected={informations.birthdate} 
                    onChange={(date) => onInformationsChange({ birthdate: date })} 
                    className="gray" 
                    dateFormat={"dd/MM/yyyy"}
                /> */}
            </div>
            <div
                style={{
                    display: "flex",
                    alignItems: "flex-start",
                    width: "49%",
                    flexDirection: "column",
                }}
            >
                <Label style={{ marginBottom: 10 }}>Sexe</Label>
                <Select
                    options={[
                        { value: "homme", label: "Homme" },
                        { value: "femme", label: "Femme" },
                    ]}
                    placeholder="Selectionner un sexe"
                    value={informations.sexe}
                    onChange={(value) => {
                        onInformationsChange({ sexe: value });
                    }}
                    isSearchable={false}
                    styles={{
                        control: () => ({
                            marginTop: "-10px",
                            backgroundColor: "#F8F9FB",
                            padding: 5,
                            marginTop: 10,
                            display: "flex",
                            borderRadius: 5,
                            width: "100%",
                            fontFamily: "CenturyGothicRegular",
                        }),
                    }}
                />
            </div>
            {/* <Input noIcon className="half" label="Sexe" value={informations.sexe} onChange={(e) => onInformationsChange({ sexe: e.target.value })} /> */}
        </Row>
        <Row className="spaced-items">
            <Input
                noIcon
                className="half"
                placeholder="Email"
                label="Email"
                value={informations.email}
                onChange={(e) =>
                    onInformationsChange({ email: e.target.value })
                }
            />
            <Input
                noIcon
                className="half"
                placeholder="N° de téléphone"
                label="N° de téléphone"
                value={informations.phone_number}
                onChange={(e) =>
                    onInformationsChange({ phone_number: e.target.value })
                }
            />
        </Row>
        <Row className="spaced-items">
            <Input
                noIcon
                className="half"
                placeholder="Ville"
                label="Ville"
                value={informations.address_city}
                onChange={(e) =>
                    onInformationsChange({ address_city: e.target.value })
                }
            />
            <Input
                noIcon
                className="half"
                placeholder="Code Postal"
                label="Code Postal"
                value={informations.address_zip_code}
                onChange={(e) =>
                    onInformationsChange({ address_zip_code: e.target.value })
                }
            />
        </Row>
        <Input
            noIcon
            className=""
            placeholder="Adresse"
            label="Adresse"
            value={informations.address_line}
            onChange={(e) =>
                onInformationsChange({ address_line: e.target.value })
            }
        />
    </div>
);

function Patients(props) {
    let history = useHistory();
    let user = useSelector((state) => state.user);

    let [count, setCount] = useState(0);
    let [patients, setPatients] = useState([]);
    let [page, setPage] = useState(0);
    // let [limit, setLimit] = useState(50);
    let [sort, setSort] = useState("-id");

    let [needle, setNeedle] = useState("");

    const [showAddClientModal, setShowAddClientModal] = useState(false);

    const agencies = useSelector((state) => state.agencies);

    let typingTimer = useRef();

    const [informations, setInformations] = useState({
        lastname: "",
        firstname: "",
        email: "",
        phone_number: "",
        birthdate: null,
        address_city: "",
        address_line: "",
        address_zip_code: "",
        sexe: "",
        picture: "",
    });

    useEffect(() => {
        reloadData();
    }, [page, sort]);

    useEffect(() => {
        clearTimeout(typingTimer.current);
        typingTimer.current = setTimeout(() => {
            reloadData();
        }, 500);
    }, [needle]);

    let setDataForm = (data) => {
        let lines = data.map((el) => {
            return {
                id: el.id,
                name: el.lastname + " " + el.firstname,
                phone_number:
                    el.phone_number.length > 0 ? el.phone_number : "N/C",
                email: el.email.length > 0 ? el.email : "N/C",
                agencies_ids: el.agencies,
                human_status: el.human_status,
                // agencies: (el.agencies || []).map((el) => (agencies || []).find((agency) => agency.id == el).name ).join(" / ")
            };
        });

        if (agencies && agencies.length > 0) {
            lines = lines.map((el) => {
                return {
                    ...el,
                    agencies: (el.agencies_ids || [])
                        .map(
                            (el) =>
                                (agencies || []).find(
                                    (agency) => agency.id == el
                                ).name
                        )
                        .join(" / "),
                };
            });
        }

        setPatients(lines);
    };

    let reloadData = () => {
        let offset = (page - 1) * 50;

        if (offset < 0) {
            offset = 0;
        }

        MyLaserApi.getAllClients(offset, 50, sort, needle).then((res) => {
            setCount(res.count);
            setDataForm(res.data);
        });
    };

    const CreateClient = () => {
        const client = {
            lastname: informations.lastname,
            firstname: informations.firstname,
            email: informations.email,
            phone_number: informations.phone_number,
            birthdate: moment(informations.birthdate).format("DD/MM/YYYY"),
            address_city: informations.address_city,
            address_line: informations.address_line,
            address_zip_code: informations.address_zip_code,
            sexe: informations.sexe.value,
            picture: informations.picture,
        };

        return MyLaserApi.CreateClient(client).then((res) => {
            if (res.status === 200) {
                toast.success("Patient ajouté avec succès.");
                MyLaserApi.getAllClients();
                setShowAddClientModal(false);
                reloadData();
            } else {
                toast.error("Une erreur s'est produite !");
            }
        });
    };

    const onInformationsChange = (obj) => {
        setInformations((prevState) => {
            return {
                ...prevState,
                ...obj,
            };
        });
    };

    if (agencies.length == 0) {
        return <div></div>;
    }

    return (
        <Container fluid className="App">
            <Row>
                <Col
                    md={12}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Header
                        title="Patients My Laser"
                        subtitle="Ayez une vue d’ensemble sur tous les patients My Laser"
                    />

                    <Row>
                        <Button
                            className="blue"
                            style={{
                                width: "20%",
                                marginRight: "45px",
                                marginLeft: "auto",
                            }}
                            onClick={() => {
                                // onImageUpload();
                                setShowAddClientModal(true);
                            }}
                        >
                            Ajouter un patient
                        </Button>
                    </Row>

                    <ContainerPatients>
                        <Block>
                            <HeaderPatient>
                                <Text
                                    className="bold"
                                    style={{ marginBottom: 10 }}
                                >
                                    Rechercher un patient :
                                </Text>
                                <Row className="spaced-items">
                                    <Input
                                        style={{ width: "100%" }}
                                        noIcon
                                        className="small"
                                        placeholder="Ecrivez ce que vous cherchez, cela peut être un nom, prénom, téléphone, email, numéro de dossier..."
                                        value={needle}
                                        onChange={(e) =>
                                            setNeedle(e.target.value)
                                        }
                                        id="input-needle"
                                    />
                                </Row>
                            </HeaderPatient>

                            {patients.length > 0 && (
                                <DataTable
                                    // title={`Mes préstations (du ${moment(intervalDate.start).format('LL')} au ${moment(intervalDate.end).format('LL')})`}
                                    noDataComponent="Il n'y a aucun patient à afficher"
                                    // paginationResetDefaultPage={resetPaginationToggle}
                                    // subHeader
                                    // subHeaderComponent={subHeaderComponentMemo}
                                    onSort={(column, sortDirection) => {
                                        setSort(
                                            (sortDirection === "asc"
                                                ? "+"
                                                : "-") + column.id
                                        );
                                    }}
                                    sortServer
                                    onRowClicked={(row) => {
                                        history.push(
                                            `/patients/${row.id}/informations`
                                        );
                                    }}
                                    columns={[
                                        {
                                            id: "id",
                                            name: "N° Dossier",
                                            selector: (row) => row.id,
                                            sortable: true,
                                        },
                                        {
                                            name: "Nom & Prénom",
                                            selector: (row) => row.name,
                                            sortable: true,
                                        },
                                        {
                                            name: "Téléphone",
                                            selector: (row) => row.phone_number,
                                            sortable: true,
                                        },
                                        {
                                            name: "Email",
                                            selector: (row) => row.email,
                                            sortable: true,
                                        },
                                        {
                                            name: "Centres",
                                            selector: (row) => {
                                                if (
                                                    row.agencies_ids.includes(
                                                        user.agency_id
                                                    )
                                                ) {
                                                    return (
                                                        <b
                                                            style={{
                                                                textDecoration:
                                                                    "underline",
                                                            }}
                                                        >
                                                            {" "}
                                                            {row.agencies}{" "}
                                                        </b>
                                                    );
                                                } else if (row.agencies != "") {
                                                    return row.agencies;
                                                } else {
                                                    return "N/C";
                                                }
                                            },
                                        },
                                    ]}
                                    data={patients}
                                    paginationComponentOptions={{
                                        noRowsPerPage: true,
                                        rowsPerPageText: "Elements par page",
                                        rangeSeparatorText: "de",
                                        selectAllRowsItem: false,
                                        selectAllRowsItemText: "Suivant",
                                    }}
                                    conditionalRowStyles={[
                                        {
                                            when: (row) =>
                                                row.human_status === "Clôturé",
                                            style: {
                                                backgroundColor:
                                                    "#E9868F!important",
                                            },
                                        },
                                        {
                                            when: (row) =>
                                                row.human_status === "Suspendu",
                                            style: {
                                                backgroundColor:
                                                    "#FED868!important",
                                            },
                                        },
                                    ]}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={count}
                                    // onChangeRowsPerPage={handlePerRowsChange}
                                    // paginationDefaultPage={page + 1}
                                    paginationPerPage={50}
                                    onChangePage={(newPage) => {
                                        setPage(newPage);
                                    }}
                                />
                            )}
                        </Block>
                    </ContainerPatients>
                </Col>
            </Row>

            <Modal
                show={showAddClientModal}
                onHide={() => setShowAddClientModal(false)}
                title={"Ajout d’un patient"}
                buttonText="Ajouter le patient "
                size={"xl"}
                onButtonClick={CreateClient}
            >
                <Form
                    informations={informations}
                    onInformationsChange={onInformationsChange}
                />
            </Modal>
        </Container>
    );
}

export default Patients;
