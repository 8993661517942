import styled from "styled-components";
import { Container, Col, Badge } from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { useSelector } from "react-redux";

import "react-calendar/dist/Calendar.css";

import TableWithStatistics from "../../components/Tables/TableWithStatistics";

//components
import Header from "../../components/Header";
import DatePicker from "../../components/DatePicker";
import Employee from "../../components/Employee";

//api
import MyLaserApi from "../../api/MyLaserApi";

import { useHistory, useParams } from "react-router";

import Switch from "react-ios-switch";

import DataTable from "react-data-table-component";

const ContainerEmployee = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100vh;
    background-color: transparent;
    padding-top: 30px;
    margin-top: 20px;
    width: 96%;
`;
const Block = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    background-color: #fff;
    margin-top: 20px;
    border-radius: 10px;
    width: 100%;
    box-shadow: 5px 2px 5px rgba(16, 41, 53, 0.1);
`;

const Text = styled.span`
    margin-left: 10px;
    font-family: CenturyGothicRegular;
    color: #102935;
    font-size: 12px;
    &.bold {
        font-family: CenturyGothicBold;
    }
    &.big {
        font-family: CenturyGothicBold;
        font-size: 20px;
    }
    &.white {
        color: #fff;
    }
    &.orange {
        color: #f0b594;
    }
    &.size-plus {
        font-size: 19px;
    }
`;
const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 20px;
    &.no-width {
        width: auto;
    }
    &.spaced-items {
        justify-content: space-between;
    }
`;
const Option = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 170px;
    background-color: #edf0f5;
    color: #102935;
    padding: 10px;
    &.selected {
        background-color: #102935;
        color: #fff;
        border-radius: 10px;
        width: 189px;
    }
    &.left {
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
    }
    &.right {
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
    }
`;

const List = styled.div`
    margin-top: 20px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
`;

const OptionButton = styled.button`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;
    background-color: #edf0f5;
    color: #102935;
    padding: 10px;
    font-family: CenturyGothicRegular;
    border: none;
    border-right: 1px solid #cdd3d9;

    &.selected {
        font-family: CenturyGothicBold;
        background-color: #102935;
        color: #fff;
        border-radius: 10px;
    }
    &.last {
        border-right: none;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }
`;

function Agency() {
    let params = useParams();
    let history = useHistory();

    let [type, setType] = useState("");

    let [agency, setAgency] = useState({});

    let [employees, setEmployees] = useState([]);
    let [notes, setNotes] = useState([]);
    let [treatmentsUnpaid, setTreatmentsUnpaid] = useState([]);
    let [treatmentDiscountSuspects, setTreatmentDiscountSuspects] = useState(
        []
    );
    let [sessionsOfToday, setSessionsOfToday] = useState([]);
    let [sessionsOfAll, setSessionsOfAll] = useState([]);

    let [intervalDate, setIntervalDate] = useState({
        start: moment().subtract(7, "days").startOf("day").toDate(),
        end: moment().endOf("day").toDate(),
    });

    let [statisticsType, setStatisticsType] = useState("payments");

    const user = useSelector((state) => state.user);

    const agencies = useSelector((state) => state.agencies);

    let agenciesTable = useRef();

    const [showOnlyPendingNP, setShowOnlyPendingNP] = useState(true);

    let [statsTotal, setStatsTotal] = useState({
        payments: 0,
        sessions: 0,
    });

    let reloadEmployees = (cb) => {
        MyLaserApi.call({ url: `/agencies/${params.id}/users` }).then(
            ({ data }) => {
                setEmployees(data);
            }
        );
    };

    useEffect(() => {
        setType("activity");
    }, []);

    useEffect(() => {
        reloadEmployees();

        MyLaserApi.call({
            url: `/agencies/${params.id}/sessionswithnotes`,
        }).then(({ data }) => {
            setNotes(data);
        });

        MyLaserApi.call({
            url: `/agencies/${params.id}/treatments/unpaid`,
        }).then(({ data }) => {
            setTreatmentsUnpaid(
                data.filter(
                    (row) =>
                        row.forced_price - row.discount - row.total_paid >= 1
                )
            );
        });

        MyLaserApi.call({ url: `/agencies/${params.id}/sessions/today` }).then(
            ({ data }) => {
                setSessionsOfToday(data);
            }
        );

        MyLaserApi.call({ url: `/agencies/${params.id}/sessions/all` }).then(
            ({ data }) => {
                setSessionsOfAll(data);
            }
        );

        reloadTreatmentDiscountSuspects();
    }, [params.id, type]);

    let reloadTreatmentDiscountSuspects = () => {
        MyLaserApi.call({
            url: `/agencies/${params.id}/treatments/discounts/suspects`,
        }).then(({ data }) => {
            setTreatmentDiscountSuspects(data);
        });
    };

    useEffect(() => {
        if (agencies && agencies.length > 0) {
            setAgency(agencies.find((e) => e.id == params.id));
        }
    }, [agencies, params.id]);

    useEffect(() => {
        setIntervalDate({ ...intervalDate });
    }, [employees]);

    if (type === "") {
        return <></>;
    }

    return (
        <Container fluid className="App">
            <Row>
                <Col
                    className="col"
                    md={12}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Header
                        user={user}
                        title={`Hello ${user.firstname},`}
                        subtitle="Bienvenue sur ton espace de travail"
                        icon
                    />

                    <ContainerEmployee>
                        <Row className="center">
                            <OptionButton
                                className={
                                    type === "activity" ? "selected" : null
                                }
                                onClick={() => setType("activity")}
                            >
                                Activité
                            </OptionButton>

                            <OptionButton
                                className={type === "notes" ? "selected" : null}
                                onClick={() => setType("notes")}
                            >
                                <span>
                                    Notes particulières{" "}
                                    <Badge
                                        pill
                                        bg="success"
                                        style={{
                                            background: "rgb(255, 150, 141)",
                                        }}
                                    >
                                        {
                                            notes.filter(
                                                (el) =>
                                                    el.special_note_status == 1
                                            ).length
                                        }
                                    </Badge>
                                </span>
                            </OptionButton>

                            <OptionButton
                                className={
                                    type === "treatments-unpaid"
                                        ? "selected"
                                        : null
                                }
                                onClick={() => setType("treatments-unpaid")}
                            >
                                <span>
                                    Traitements incomplet{" "}
                                    <Badge
                                        pill
                                        bg="success"
                                        style={{
                                            background: "rgb(255, 150, 141)",
                                        }}
                                    >
                                        {treatmentsUnpaid.length}
                                    </Badge>
                                </span>
                            </OptionButton>
                        </Row>

                        <Row className="center">
                            <OptionButton
                                className={
                                    type === "discounts-suspect"
                                        ? "selected "
                                        : ""
                                }
                                onClick={() => setType("discounts-suspect")}
                            >
                                <span>
                                    Réductions suspectes{" "}
                                    <Badge
                                        pill
                                        bg="success"
                                        style={{
                                            background: "rgb(255, 150, 141)",
                                        }}
                                    >
                                        {treatmentDiscountSuspects.length}
                                    </Badge>
                                </span>
                            </OptionButton>

                            <OptionButton
                                className={
                                    type === "payment-forgotten"
                                        ? "selected"
                                        : "last"
                                }
                                onClick={() => setType("payment-forgotten")}
                            >
                                <span>
                                    Encaissements oubliés{" "}
                                    <Badge
                                        pill
                                        bg="success"
                                        style={{
                                            background: "rgb(255, 150, 141)",
                                        }}
                                    >
                                        {sessionsOfToday.length}
                                    </Badge>
                                </span>
                            </OptionButton>

                            <OptionButton
                                className={
                                    type === "payment-forgotten-all"
                                        ? "selected last"
                                        : "last"
                                }
                                onClick={() => setType("payment-forgotten-all")}
                            >
                                <span>
                                    Encaissements oubliés (Toujours){" "}
                                    <Badge
                                        pill
                                        bg="success"
                                        style={{
                                            background: "rgb(255, 150, 141)",
                                        }}
                                    >
                                        {sessionsOfAll.length}
                                    </Badge>
                                </span>
                            </OptionButton>
                        </Row>

                        {type === "treatments-unpaid" && (
                            <>
                                <Row className="spaced-items">
                                    <Text className="big">{agency.name}</Text>
                                </Row>

                                <Block className="withoutstripped">
                                    <DataTable
                                        title={`Traitements incomplets de ${agency.name}`}
                                        noDataComponent="Il n'y a aucun traitement à afficher"
                                        paginationPerPage={30}
                                        onRowClicked={(row) => {
                                            history.push(
                                                `/patients/${row.agency_client_id}/payments`
                                            );
                                        }}
                                        columns={[
                                            {
                                                name: "Date",
                                                sortable: true,
                                                selector: (row) =>
                                                    moment(row.date).format(
                                                        "LLLL"
                                                    ),
                                            },
                                            {
                                                name: "Traitement",
                                                sortable: true,
                                                selector: (row) =>
                                                    row.type_human,
                                            },
                                            {
                                                name: "Total payé",
                                                sortable: true,
                                                selector: (row) =>
                                                    row.total_paid +
                                                    ` ${global.currencySymbol}`,
                                            },
                                            {
                                                name: "Total à due",
                                                sortable: true,
                                                selector: (row) => {
                                                    return (
                                                        (
                                                            row.forced_price -
                                                            row.discount
                                                        ).toFixed(2) +
                                                        ` ${global.currencySymbol}`
                                                    );
                                                },
                                            },
                                            {
                                                name: "Reste à payer",
                                                sortable: true,
                                                selector: (row) => {
                                                    return (
                                                        (
                                                            row.forced_price -
                                                            row.discount -
                                                            row.total_paid
                                                        ).toFixed(2) +
                                                        ` ${global.currencySymbol}`
                                                    );
                                                },
                                            },
                                            {
                                                name: "Patient",
                                                sortable: true,
                                                selector: (row) =>
                                                    row.client_firstname +
                                                    " " +
                                                    row.client_lastname,
                                            },
                                            {
                                                name: "Encaissement",
                                                sortable: true,
                                                selector: (row) =>
                                                    row.employee_firstname +
                                                    " " +
                                                    row.employee_lastname,
                                            },
                                        ]}
                                        data={treatmentsUnpaid}
                                    />
                                </Block>
                            </>
                        )}

                        {type === "discounts-suspect" && (
                            <>
                                <Row className="spaced-items">
                                    <Text className="big">{agency.name}</Text>
                                </Row>

                                <Block className="withoutstripped">
                                    <DataTable
                                        title={`Traitements avec une remise suspecte au centre ${agency.name}`}
                                        noDataComponent="Il n'y a aucun traitement à afficher"
                                        paginationPerPage={30}
                                        onRowClicked={(row) => {
                                            history.push(
                                                `/patients/${row.agency_client_id}/payments`
                                            );
                                        }}
                                        columns={[
                                            {
                                                name: "Date",
                                                sortable: true,
                                                selector: (row) =>
                                                    moment(row.date).format(
                                                        "LL"
                                                    ),
                                            },
                                            {
                                                name: "Traitement",
                                                sortable: true,
                                                selector: (row) =>
                                                    row.treatment.type_human,
                                            },
                                            {
                                                name: "Montant de la remise",
                                                sortable: true,
                                                selector: (row) =>
                                                    `${row.amount} ${global.currencySymbol} soit ${row.percentage} %`,
                                            },
                                            {
                                                name: "Raison",
                                                sortable: true,
                                                grow: true,
                                                selector: (row) => {
                                                    return row.reason;
                                                },
                                            },
                                            {
                                                name: "Montant du traitement",
                                                sortable: true,
                                                selector: (row) => {
                                                    return (
                                                        row.treatment.forced_price.toFixed(
                                                            2
                                                        ) +
                                                        ` ${global.currencySymbol}`
                                                    );
                                                },
                                            },
                                            {
                                                name: "Patient",
                                                sortable: true,
                                                selector: (row) =>
                                                    row.agency_client
                                                        .firstname +
                                                    " " +
                                                    row.agency_client.lastname,
                                            },
                                            {
                                                name: "Créateur",
                                                sortable: true,
                                                selector: (row) =>
                                                    row.employee.firstname +
                                                    " " +
                                                    row.employee.lastname,
                                            },
                                            {
                                                name: "Action",
                                                sortable: true,
                                                selector: (row) => (
                                                    <span
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            MyLaserApi.UnsuspectTreatmentDiscount(
                                                                row.agency_id,
                                                                row.id
                                                            ).then((res) => {
                                                                reloadTreatmentDiscountSuspects();
                                                            });
                                                        }}
                                                        style={{
                                                            marginLeft: "15px",
                                                            color: "rgb(255, 150, 141)",
                                                        }}
                                                    >
                                                        Ignorer
                                                    </span>
                                                ),
                                            },
                                        ]}
                                        data={treatmentDiscountSuspects}
                                    />
                                </Block>
                            </>
                        )}

                        {type === "notes" && (
                            <>
                                <Row className="spaced-items">
                                    <Text className="big">{agency.name}</Text>
                                </Row>

                                <div style={{ marginLeft: "10px" }}>
                                    <input
                                        checked={showOnlyPendingNP}
                                        onChange={(el) =>
                                            setShowOnlyPendingNP(
                                                !showOnlyPendingNP
                                            )
                                        }
                                        type="checkbox"
                                        id="archived-consent"
                                    />{" "}
                                    <label for="archived-consent">
                                        Afficher uniquement les notes en attente
                                    </label>
                                </div>

                                <Block className="withoutstripped">
                                    <DataTable
                                        title={`Notes particulières de ${agency.name}`}
                                        noDataComponent="Il n'y a aucune notes particulières à afficher"
                                        paginationPerPage={30}
                                        onRowClicked={(row) => {
                                            history.push(
                                                `/patients/${row.agency_client_id}/treatments/laser/notes/${row.id}/details`
                                            );
                                        }}
                                        conditionalRowStyles={[
                                            {
                                                when: (row) =>
                                                    row.special_note_status ==
                                                    1,
                                                style: {
                                                    backgroundColor:
                                                        "rgba(215, 180, 180, 0.8)!important",
                                                },
                                            },
                                            {
                                                when: (row) =>
                                                    row.special_note_status ==
                                                    2,
                                                style: {
                                                    backgroundColor:
                                                        "#B4D7C1!important",
                                                },
                                            },
                                        ]}
                                        columns={[
                                            {
                                                name: "Date",
                                                sortable: true,
                                                selector: (row) =>
                                                    moment(row.date).format(
                                                        "LLLL"
                                                    ),
                                            },
                                            {
                                                name: "Zone de traitement",
                                                sortable: true,
                                                wrap: true,
                                                selector: (row) =>
                                                    `${row.type_human} (${row.area})`,
                                            },
                                            {
                                                name: "Effets sec.",
                                                sortable: true,
                                                wrap: true,
                                                selector: (row) =>
                                                    row.special_note_secondary_effect,
                                            },
                                            {
                                                name: "Commentaire",
                                                sortable: true,
                                                wrap: true,
                                                grow: 2,
                                                selector: (row) =>
                                                    row.special_note_comment,
                                            },
                                            {
                                                name: "Patient",
                                                sortable: true,
                                                selector: (row) =>
                                                    row.agency_client
                                                        ? row.agency_client
                                                              .firstname +
                                                          " " +
                                                          row.agency_client
                                                              .lastname
                                                        : "",
                                            },
                                            {
                                                name: "Praticien(ne)",
                                                sortable: true,
                                                selector: (row) =>
                                                    row.employee
                                                        ? row.employee
                                                              .firstname +
                                                          " " +
                                                          row.employee.lastname
                                                        : "",
                                            },
                                        ]}
                                        data={notes.filter((note) => {
                                            if (showOnlyPendingNP) {
                                                return (
                                                    note.special_note_status ==
                                                    1
                                                );
                                            }

                                            return true;
                                        })}
                                    />
                                </Block>
                            </>
                        )}

                        {type === "payment-forgotten" && (
                            <>
                                <Row className="spaced-items">
                                    <Text className="big">
                                        Encaissements oubliés (3 jours)
                                    </Text>
                                </Row>

                                <Block className="withoutstripped">
                                    <DataTable
                                        title={`Encaissements oubliés au centre ${agency.name}`}
                                        noDataComponent="Il n'y a aucun traitement à afficher"
                                        paginationPerPage={30}
                                        onRowClicked={(row) => {
                                            history.push(
                                                `/patients/${row.agency_client_id}/payments`
                                            );
                                        }}
                                        columns={[
                                            {
                                                name: "Traitement",
                                                sortable: true,
                                                selector: (row) =>
                                                    `${row.type_human} ${
                                                        row.pack
                                                            ? " - " + row.pack
                                                            : ""
                                                    }`,
                                                wrap: true,
                                            },
                                            {
                                                name: "Montant du traitement",
                                                sortable: true,
                                                selector: (row) => {
                                                    return (
                                                        row.forced_price.toFixed(
                                                            2
                                                        ) +
                                                        ` ${global.currencySymbol}`
                                                    );
                                                },
                                            },
                                            {
                                                name: "Patient",
                                                sortable: true,
                                                selector: (row) =>
                                                    row.agency_client
                                                        .firstname +
                                                    " " +
                                                    row.agency_client.lastname,
                                            },
                                            {
                                                name: "Praticien",
                                                sortable: true,
                                                selector: (row) =>
                                                    row.employee.firstname +
                                                    " " +
                                                    row.employee.lastname,
                                            },
                                        ]}
                                        data={sessionsOfToday}
                                    />
                                </Block>
                            </>
                        )}

                        {type === "payment-forgotten-all" && (
                            <>
                                <Row className="spaced-items">
                                    <Text className="big">
                                        Encaissements oubliés (Depuis toujours)
                                    </Text>
                                </Row>

                                <Block className="withoutstripped">
                                    <DataTable
                                        title={`Encaissements oubliés au centre ${agency.name}`}
                                        noDataComponent="Il n'y a aucun traitement à afficher"
                                        paginationPerPage={30}
                                        onRowClicked={(row) => {
                                            history.push(
                                                `/patients/${row.agency_client_id}/payments`
                                            );
                                        }}
                                        columns={[
                                            {
                                                name: "Traitement",
                                                sortable: true,
                                                selector: (row) =>
                                                    `${row.type_human} ${
                                                        row.pack
                                                            ? " - " + row.pack
                                                            : ""
                                                    }`,
                                                wrap: true,
                                            },
                                            {
                                                name: "Montant du traitement",
                                                sortable: true,
                                                selector: (row) => {
                                                    return (
                                                        row.forced_price.toFixed(
                                                            2
                                                        ) +
                                                        ` ${global.currencySymbol}`
                                                    );
                                                },
                                            },
                                            {
                                                name: "Date du paiement",
                                                sortable: true,
                                                selector: (row) =>
                                                    moment(row.date).format(
                                                        "LLLL"
                                                    ),
                                            },
                                            {
                                                name: "Patient",
                                                sortable: true,
                                                selector: (row) =>
                                                    row.client_firstname +
                                                    " " +
                                                    row.client_lastname,
                                            },
                                            {
                                                name: "Praticien",
                                                sortable: true,
                                                selector: (row) =>
                                                    row.employee_firstname +
                                                    " " +
                                                    row.employee_lastname,
                                            },
                                        ]}
                                        data={sessionsOfAll}
                                    />
                                </Block>
                            </>
                        )}

                        {type === "activity" && (
                            <>
                                <Row
                                    className="spaced-items"
                                    style={{ margin: 0 }}
                                >
                                    <Text className="big">
                                        L’activité de {agency.name}
                                    </Text>
                                </Row>

                                <Block>
                                    <Row className="spaced-items">
                                        <Row>
                                            <Option
                                                className={`${
                                                    statisticsType == "payments"
                                                        ? "selected"
                                                        : ""
                                                } left`}
                                                onClick={() =>
                                                    setStatisticsType(
                                                        "payments"
                                                    )
                                                }
                                            >
                                                <Text
                                                    className={`${
                                                        statisticsType ==
                                                        "payments"
                                                            ? "bold white"
                                                            : ""
                                                    }`}
                                                >
                                                    Chiffre d’Affaires
                                                </Text>
                                                <Text
                                                    className={`${
                                                        statisticsType ==
                                                        "payments"
                                                            ? "size-plus white"
                                                            : "size-plus"
                                                    }`}
                                                >
                                                    {statsTotal.payments}{" "}
                                                    {global.currencySymbol}
                                                </Text>
                                            </Option>

                                            <Option
                                                className={`${
                                                    statisticsType == "sessions"
                                                        ? "selected"
                                                        : ""
                                                } right`}
                                                onClick={() =>
                                                    setStatisticsType(
                                                        "sessions"
                                                    )
                                                }
                                            >
                                                <Text
                                                    className={`${
                                                        statisticsType ==
                                                        "sessions"
                                                            ? "bold white"
                                                            : ""
                                                    }`}
                                                >
                                                    Patients
                                                </Text>
                                                <Text
                                                    className={`${
                                                        statisticsType ==
                                                        "sessions"
                                                            ? "size-plus white"
                                                            : "size-plus"
                                                    }`}
                                                >
                                                    {statsTotal.sessions}
                                                </Text>
                                            </Option>
                                        </Row>

                                        <DatePicker
                                            onChange={(dates) => {
                                                setIntervalDate(dates);
                                            }}
                                        />
                                    </Row>
                                </Block>

                                <Block>
                                    <TableWithStatistics
                                        title="Chiffre d'affaire par moyen de paiement"
                                        url={`/agencies/${params.id}/payments/statistics`}
                                        normalBehivior={true}
                                        intervalDate={intervalDate}
                                        statisticsType={"payments"}
                                        headerColumn="type_human"
                                        setHeadersFunction={(data) => {
                                            return [
                                                {
                                                    id: "agency_income_type_1_amount_total",
                                                    name: "Espèce",
                                                },
                                                {
                                                    id: "agency_income_type_2_amount_total",
                                                    name: "Carte Bancaire",
                                                },
                                                {
                                                    id: "agency_income_type_3_amount_total",
                                                    name: "ALMA",
                                                },
                                                {
                                                    id: "agency_income_type_4_amount_total",
                                                    name: "Chèque",
                                                },
                                            ];
                                        }}
                                        onReload={() => {}}
                                    />
                                </Block>

                                <Block>
                                    {employees.length > 0 && (
                                        <TableWithStatistics
                                            // ref={sessionsTable}
                                            title="Chiffre d'affaire par praticien"
                                            // url="/agencies/clients/sessions/statistics"
                                            url={`/agencies/${params.id}/payments/employees/statistics`}
                                            intervalDate={intervalDate}
                                            statisticsType={statisticsType}
                                            headerColumn="employee_id"
                                            setHeadersFunction={(data) => {
                                                return data[statisticsType]
                                                    .map((el) => el.employee_id)
                                                    .filter(global.onlyUnique)
                                                    .map((el) => {
                                                        const foundEmployee =
                                                            employees.find(
                                                                (emp) =>
                                                                    emp.id == el
                                                            );
                                                        if (foundEmployee) {
                                                            return {
                                                                ...foundEmployee,
                                                                name:
                                                                    foundEmployee.firstname +
                                                                    " " +
                                                                    foundEmployee.lastname,
                                                            };
                                                        }
                                                        return null; // or handle the case when an employee is not found
                                                    })
                                                    .filter(
                                                        (el) => el !== null
                                                    );
                                            }}
                                            onReload={() => {}}
                                        />
                                    )}
                                </Block>

                                <Block>
                                    <TableWithStatistics
                                        ref={agenciesTable}
                                        title="Chiffre d'affaire par prestation"
                                        url={`/agencies/${params.id}/statistics`}
                                        intervalDate={intervalDate}
                                        statisticsType={statisticsType}
                                        headerColumn="type_human"
                                        setHeadersFunction={(data, type) => {
                                            if (type == "sessions") {
                                                return data.sessions
                                                    .map((el) => el.type_human)
                                                    .filter(global.onlyUnique)
                                                    .map((el, index) => ({
                                                        name: el,
                                                        id: el,
                                                    }));
                                            } else {
                                                return data.payments
                                                    .map((el) => el.type_human)
                                                    .filter(global.onlyUnique)
                                                    .map((el, index) => ({
                                                        name: el,
                                                        id: el,
                                                    }));
                                            }
                                        }}
                                        onReload={() => {
                                            setStatsTotal({
                                                payments:
                                                    agenciesTable &&
                                                    agenciesTable.current &&
                                                    agenciesTable.current.getValueFromStatistic(
                                                        "payments",
                                                        "total_amount"
                                                    ),
                                                sessions:
                                                    agenciesTable &&
                                                    agenciesTable.current &&
                                                    agenciesTable.current.getValueFromStatistic(
                                                        "sessions",
                                                        "sessions_count"
                                                    ),
                                            });
                                        }}
                                    />
                                </Block>

                                <List>
                                    {employees.map((employee) => {
                                        return (
                                            <Employee
                                                text={`${employee.firstname} ${employee.lastname}`}
                                                subtext={
                                                    <>
                                                        {" "}
                                                        {
                                                            employee.email
                                                        } <br />{" "}
                                                        {employee.phone_number}{" "}
                                                    </>
                                                }
                                                onClick={() => {
                                                    // setActiveEmployee(employee)
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        textAlign: "center",
                                                        margin: "auto",
                                                    }}
                                                >
                                                    <Switch
                                                        checked={
                                                            employee.status
                                                        }
                                                        onChange={async (
                                                            checked
                                                        ) => {
                                                            await MyLaserApi.call(
                                                                {
                                                                    url: `/employees/${employee.id}/status`,
                                                                    method: "PUT",
                                                                }
                                                            );
                                                            reloadEmployees();
                                                        }}
                                                    />
                                                </div>
                                            </Employee>
                                        );
                                    })}
                                </List>
                            </>
                        )}
                    </ContainerEmployee>
                </Col>
            </Row>
        </Container>
    );
}

export default Agency;
