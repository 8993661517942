import { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import DataTable from "react-data-table-component";
import MyLaserApi from "../../api/MyLaserApi";
import moment from "moment";

export default forwardRef(
    (
        {
            title,
            url,
            intervalDate,
            statisticsType,
            onReload,
            setHeadersFunction,
            headerColumn,
            normalBehivior = false,
            customDateFormat = "LL",
        },
        _ref
    ) => {
        let [fullStatistics, setFullStatistics] = useState(null);
        let [headers, setHeaders] = useState([]);

        useEffect(() => {
            MyLaserApi.call({
                url: `${url}?start=${intervalDate.start.getTime() / 1000}&end=${
                    intervalDate.end.getTime() / 1000
                }`,
            }).then(({ data }) => {
                setHeaders(setHeadersFunction(data));
                setFullStatistics(data);
                onReload();
            });
        }, [intervalDate]);

        useEffect(() => {
            if (statisticsType && fullStatistics) {
                setHeaders(setHeadersFunction(fullStatistics, statisticsType));
            }
        }, [statisticsType]);

        useImperativeHandle(_ref, () => ({
            getValueFromStatistic(type, column) {
                if (fullStatistics && fullStatistics[type]) {
                    let result = fullStatistics[type].reduce(
                        (tot, current) => tot + parseFloat(current[column]),
                        0
                    );

                    if (column.includes("sessions")) {
                        return result;
                    } else {
                        return global.numberWithSpaces(
                            fullStatistics[type].reduce(
                                (tot, current) =>
                                    tot + parseFloat(current[column]),
                                0
                            )
                        );
                    }
                } else {
                    return 0;
                }
            },
        }));

        if (!fullStatistics) {
            return <div />;
        }

        return (
            <DataTable
                title={`${title} (du ${moment(intervalDate.start).format(
                    "LL"
                )} au ${moment(intervalDate.end).format("LL")})`}
                noDataComponent="Il n'y a aucun c.a à afficher"
                pagination
                paginationPerPage={30}
                data={[
                    ...fullStatistics[statisticsType]
                        .map((el) => el.ndate)
                        .filter(window.onlyUnique)
                        .reverse(),
                    "TOTAL",
                ]}
                columns={[
                    {
                        name: "Date",
                        sortable: true,
                        selector: (date) => date,
                        cell: (date) =>
                            date === "TOTAL"
                                ? "TOTAL"
                                : moment(date).format(customDateFormat),
                        sortFunction: (a, b) => {
                            if (b == "TOTAL") {
                                return 0;
                            }

                            if (a > b) {
                                return 1;
                            }

                            if (b > a) {
                                return -1;
                            }

                            return 0;
                        },
                    },

                    {
                        name: "Total",
                        selector: (date) => {
                            if (date === "TOTAL") {
                                let label =
                                    statisticsType == "payments"
                                        ? "total_amount"
                                        : "sessions_count";
                                let unit =
                                    statisticsType == "payments"
                                        ? global.currencySymbol
                                        : "";

                                let arr = fullStatistics[statisticsType].reduce(
                                    (p, c) => p + parseFloat(c[label]),
                                    0
                                );

                                return (
                                    <span style={{ color: "#007bff" }}>
                                        {" "}
                                        {global.numberWithSpaces(
                                            arr,
                                            unit === global.currencySymbol
                                        )}{" "}
                                        {unit}
                                    </span>
                                );
                            }

                            let arr = fullStatistics[statisticsType].filter(
                                (el) => el.ndate === date
                            );

                            let label =
                                statisticsType == "payments"
                                    ? "total_amount"
                                    : "sessions_count";
                            let unit =
                                statisticsType == "payments"
                                    ? global.currencySymbol
                                    : "";

                            return (
                                <span style={{ color: "#007bff" }}>
                                    {" "}
                                    {global.numberWithSpaces(
                                        arr.reduce(
                                            (p, single) =>
                                                p + parseFloat(single[label]),
                                            0
                                        ),
                                        unit === global.currencySymbol
                                    )}{" "}
                                    {unit}
                                </span>
                            );
                        },
                    },

                    ...headers.map((header) => {
                        let label =
                            statisticsType == "payments"
                                ? "total_amount"
                                : "sessions_count";
                        let unit =
                            statisticsType == "payments"
                                ? global.currencySymbol
                                : "";

                        let singleResult = {};
                        let allResult = [];

                        return {
                            name: header.name.replace("MY LASER", ""),
                            cell: (date) => {
                                if (normalBehivior === true) {
                                    if (date === "TOTAL") {
                                        singleResult = fullStatistics[
                                            statisticsType
                                        ].reduce(
                                            (p, c) =>
                                                p + parseFloat(c[header.id]),
                                            0
                                        );

                                        allResult = headers
                                            .map((header) => {
                                                return fullStatistics[
                                                    statisticsType
                                                ].reduce(
                                                    (p, c) =>
                                                        p +
                                                        parseFloat(
                                                            c[header.id]
                                                        ),
                                                    0
                                                );
                                            })
                                            .filter((s) => s);
                                    } else {
                                        let arr = fullStatistics[
                                            statisticsType
                                        ].filter((el) => el.ndate === date)[0];

                                        if (arr) {
                                            allResult = [
                                                arr[
                                                    "agency_income_type_1_amount_total"
                                                ],
                                                arr[
                                                    "agency_income_type_2_amount_total"
                                                ],
                                                arr[
                                                    "agency_income_type_3_amount_total"
                                                ],
                                                arr[
                                                    "agency_income_type_4_amount_total"
                                                ],
                                            ].filter((el) => el != 0);

                                            singleResult = arr[header.id] || 0;
                                        } else {
                                            singleResult = 0;
                                        }
                                    }
                                } else {
                                    if (date === "TOTAL") {
                                        singleResult = fullStatistics[
                                            statisticsType
                                        ]
                                            .filter(
                                                (el) =>
                                                    el[headerColumn] ==
                                                    header.id
                                            )
                                            .reduce(
                                                (p, c) =>
                                                    p + parseFloat(c[label]),
                                                0
                                            );

                                        allResult = headers
                                            .map((header) => {
                                                return fullStatistics[
                                                    statisticsType
                                                ]
                                                    .filter(
                                                        (el) =>
                                                            el[headerColumn] ==
                                                            header.id
                                                    )
                                                    .reduce(
                                                        (p, c) =>
                                                            p +
                                                            parseFloat(
                                                                c[label]
                                                            ),
                                                        0
                                                    );
                                            })
                                            .filter((s) => s);
                                    } else {
                                        let arr = fullStatistics[
                                            statisticsType
                                        ].filter((el) => el.ndate === date);
                                        allResult = arr
                                            .map(
                                                (singleResult) =>
                                                    singleResult[label]
                                            )
                                            .filter((el) => el != 0);

                                        singleResult = (arr.find(
                                            (el) =>
                                                el[headerColumn] == header.id
                                        ) || { [label]: 0 })[label];
                                    }
                                }

                                if (singleResult == 0) {
                                    return (
                                        <span style={{ color: "#dc3545" }}>
                                            {" "}
                                            {global.numberWithSpaces(
                                                singleResult,
                                                unit === global.currencySymbol
                                            )}{" "}
                                            {unit}{" "}
                                        </span>
                                    );
                                } else if (
                                    Math.max.apply(null, allResult) ==
                                    singleResult
                                ) {
                                    return (
                                        <span style={{ color: "#28a745" }}>
                                            {" "}
                                            {global.numberWithSpaces(
                                                singleResult,
                                                unit === global.currencySymbol
                                            )}{" "}
                                            {unit} ↑
                                        </span>
                                    );
                                } else if (
                                    Math.min.apply(null, allResult) ==
                                    singleResult
                                ) {
                                    return (
                                        <span style={{ color: "#e6ac00" }}>
                                            {" "}
                                            {global.numberWithSpaces(
                                                singleResult,
                                                unit === global.currencySymbol
                                            )}{" "}
                                            {unit} ↓
                                        </span>
                                    );
                                } else {
                                    return (
                                        <span style={{ color: "#17a2b8" }}>
                                            {" "}
                                            {global.numberWithSpaces(
                                                singleResult,
                                                unit === global.currencySymbol
                                            )}{" "}
                                            {unit}{" "}
                                        </span>
                                    );
                                }
                            },
                        };
                    }),
                ]}
            />
        );
    }
);
