import "../App.css";
import styled from "styled-components";
import { Image } from "react-bootstrap";
import moment from "moment";
import { useSelector } from "react-redux";

//components
import Calendar from "./Calendar";
import Menu from "./Menu";
import S3Picture from "./S3Picture";
import { useHistory } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import MyLaserApi from "../api/MyLaserApi";
import { toast } from "react-toastify";

const Container = styled.div`
    display: flex;
    flex-direction: Row;
    align-items: center;
    justify-content: space-between;
    width: 96%;
    background-color: transparent;
    padding-top: 30px;
`;

const ContainerInfo = styled.div`
    color: #004085;
    /* width: 90%; */
    background-color: #cce5ff;
    border-color: #b8daff;
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
`;

const ContainerAlert = styled.div`
    color: #721c24;
    /* width: 90%; */
    background-color: #f8d7da;
    border-color: #f5c6cb;
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
const Col = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;
const Text = styled.span`
    margin-left: 10px;
    font-family: CenturyGothicRegular;
    color: #102935;
    font-size: 14px;
    &.bold {
        font-family: CenturyGothicBold;
        font-size: 15px;
    }
    &.big {
        font-family: CenturyGothicBold;
        font-size: 25px;
    }
`;
const Line = styled.div`
    background-color: rgba(16, 41, 53, 0.06);
    width: 1px;
    height: 25px;
    margin-left: 10px;
    margin-right: 10px;
`;
const ImageContainer = styled.div`
    background-color: #fff;
    padding: 5px;
    border-radius: 50px;
    border: solid;
    border-color: #edf0f5;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const BContainer = styled.div`
    display: flex;
    flex-direction: Row;
    align-items: center;
    padding: 10px;
    background-color: #fff;
    border-radius: 7px;
    color: black;
    margin-top: 25px;
    font-family: CenturyGothicRegular;
    color: #102935;
    font-size: 14px;
`;

function MissingPayment(props) {
    const [sessionsOfToday, setSessionsOfToday] = useState([]);
    const user = useSelector((state) => state.user);

    const reload = () => {
        if (user.agency_id) {
            MyLaserApi.call({
                url: `/agencies/${user.agency_id}/sessions/today`,
            }).then(({ data }) => {
                if (data) {
                    setSessionsOfToday(data);
                }
            });
        }
    };

    useEffect(() => {
        if (user && sessionsOfToday.length === 0) {
            reload();
        }
    }, []);

    useEffect(() => {
        if (user && sessionsOfToday.length === 0) {
            reload();
        }
    }, [user]);

    if (!user.agency_id) {
        return <></>;
    }

    return (
        <div>
            <Dropdown>
                <Dropdown.Toggle
                    style={{
                        backgroundColor: "transparent",
                        borderColor: "transparent",
                    }}
                >
                    <BContainer>
                        <Image
                            style={{ marginLeft: 0 }}
                            src={"/currency-euro.svg"}
                        />
                        {sessionsOfToday.length !== 0 && (
                            <div style={{ color: "red" }}>
                                {" "}
                                | {sessionsOfToday.length}{" "}
                            </div>
                        )}
                    </BContainer>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {sessionsOfToday.length === 0 && (
                        <Dropdown.Item style={{ borderBottomWidth: 1 }}>
                            <Text className="bold">
                                {" "}
                                Aucun paiment en attente aujourd'hui.
                            </Text>
                        </Dropdown.Item>
                    )}

                    {sessionsOfToday.map((row) => (
                        <Dropdown.Item
                            href={`/patients/${row.agency_client_id}/payments`}
                            style={{ borderBottomWidth: 1 }}
                        >
                            <Text className="bold">
                                {row.agency_client.firstname}{" "}
                                {row.agency_client.lastname} ~{" "}
                                {row.forced_price.toFixed(2) +
                                    ` ${global.currencySymbol}`}
                            </Text>
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}

function ClientHistory(props) {
    const params = useParams();
    const intervalRef = useRef(null);

    const [visitedClients, setVisitedClients] = useState([]);

    const reload = () => {
        let visitedClientsD = localStorage.getItem("visited_clients");

        if (visitedClientsD) {
            visitedClientsD = JSON.parse(visitedClientsD);
        } else {
            visitedClientsD = [];
        }

        setVisitedClients(visitedClientsD);
    };

    useEffect(() => {
        reload();

        intervalRef.current = setInterval(() => {
            reload();
        }, 1000 * 60);

        return () => clearInterval(intervalRef.current);
    }, []);

    useEffect(() => {
        setTimeout(() => {
            reload();
        }, 1000);
    }, [params.id]);

    return (
        <div>
            <Dropdown>
                <Dropdown.Toggle
                    style={{
                        backgroundColor: "transparent",
                        borderColor: "transparent",
                    }}
                >
                    <BContainer>
                        Historique de patient
                        <Image
                            src={"/down-arrow.svg"}
                            style={{ marginLeft: 10 }}
                        />
                    </BContainer>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {visitedClients.map((vc) => (
                        <Dropdown.Item
                            href={`/patients/${vc.id}/informations`}
                            style={{ borderBottomWidth: 1 }}
                        >
                            <Text className="bold">
                                {vc.firstname} {vc.lastname} (~{" "}
                                {moment().diff(moment(vc.date), "minutes")}{" "}
                                mins.)
                            </Text>
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}

function Header(props) {
    const client = useSelector((state) => state.client);
    const agencies = useSelector((state) => state.agencies);
    const history = useHistory();

    const [stats, setStats] = useState({
        discounts: 0,
        incomes: 0,
        needed: 0,
        refunds: 0,
    });

    const reload = () => {
        MyLaserApi.call({
            method: "GET",
            url: `/agencies/clients/${client.id}/sessions/payments/statistics`,
        }).then((data) => {
            setStats(data.data);
        });
    };

    useEffect(() => {
        if (client) {
            reload();
        }
    }, []);

    useEffect(() => {
        if (client) {
            reload();
        }
    }, [client]);

    if (props.type === "client" && client) {
        return (
            <>
                <Container>
                    <Row>
                        <ImageContainer>
                            {client.firstname.charAt(0)}.
                            {client.lastname.charAt(0)}
                        </ImageContainer>
                        <Col>
                            <Text className={"bold"}>
                                {client.firstname} {client.lastname}
                            </Text>
                            <Text>{client.email}</Text>
                            <Text>Dossier n°{client.id}</Text>
                            <Text>
                                {" "}
                                {client &&
                                agencies &&
                                agencies.length > 0 &&
                                client.agencies &&
                                client.agencies.length > 0
                                    ? client.agencies
                                          .map(
                                              (el) =>
                                                  (agencies || []).find(
                                                      (agency) =>
                                                          agency.id == el
                                                  ).name
                                          )
                                          .join(" / ")
                                    : "Aucun centre"}{" "}
                            </Text>
                            <Text>
                                Code parrainage:{" "}
                                <strong
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        toast.success("Code copié");
                                        navigator.clipboard.writeText(
                                            client.referral_code
                                        );
                                    }}
                                >
                                    {client.referral_code}
                                </strong>
                            </Text>
                        </Col>
                    </Row>
                    <Row>
                        <Calendar />
                        <ClientHistory />
                        <MissingPayment />
                        <Line />
                        <Menu />
                    </Row>
                </Container>

                {(() => {
                    if (
                        moment().diff(client.birthdate, "years", false) < 18 &&
                        !client.has_parental_authorization
                    ) {
                        return (
                            <ContainerAlert style={{ width: "100%" }}>
                                <Row>
                                    Ce patient a moins de 18 ans et n'a pas
                                    d'autorisation parentale. Vous ne pouvez pas
                                    le modifier.
                                </Row>
                                <Row>
                                    <b
                                        onClick={() => {
                                            history.push(
                                                `/patients/${client.id}/consents/create`
                                            );
                                        }}
                                    >
                                        {" "}
                                        Cliquez ici afin de lui en faire une
                                    </b>
                                </Row>
                            </ContainerAlert>
                        );
                    }
                })()}

                {(() => {
                    if (stats) {
                        let truc =
                            stats.needed_but_really -
                            stats.discounts -
                            stats.incomes;

                        if (truc > 0.1) {
                            return (
                                <ContainerAlert style={{ width: "100%" }}>
                                    <Row>
                                        Ce patient a un montant de{" "}
                                        {(
                                            stats.needed_but_really -
                                            stats.discounts -
                                            stats.incomes
                                        ).toFixed(2)}{" "}
                                        {global.currencySymbol} à régler.
                                    </Row>
                                </ContainerAlert>
                            );
                        } else if (truc < -0.1) {
                            return (
                                <ContainerInfo style={{ width: "100%" }}>
                                    <Row>
                                        Ce patient a un montant de{" "}
                                        {(
                                            (stats.needed_but_really -
                                                stats.discounts -
                                                stats.incomes) *
                                            -1
                                        ).toFixed(2)}{" "}
                                        {global.currencySymbol} de trop perçu.
                                    </Row>
                                </ContainerInfo>
                            );
                        }
                    }

                    return <></>;
                })()}
            </>
        );
    }

    return (
        <Container>
            <Row>
                {props.user && (
                    <ImageContainer>
                        <S3Picture
                            className="user-profile-img"
                            type="user-profil"
                            file={props.user.picture}
                        />

                        {props.uploadImage && (
                            <>
                                <Image
                                    src={"/edit.svg"}
                                    style={{
                                        position: "absolute",
                                        marginLeft: 30,
                                    }}
                                />
                                <input
                                    type="file"
                                    id="picture-upload"
                                    name="imageUploader"
                                    style={{
                                        position: "absolute",
                                        height: 30,
                                        width: 50,
                                        opacity: 0,
                                        marginLeft: 30,
                                    }}
                                    onChange={props.uploadImage}
                                />
                            </>
                        )}
                    </ImageContainer>
                )}
                <Col>
                    <Text className={props.icon ? "bold" : "big"}>
                        {props.title}
                    </Text>
                    <Text>{props.subtitle}</Text>
                </Col>
            </Row>
            <Row>
                <Calendar />
                <ClientHistory />
                <MissingPayment />
                <Line />
                <Menu user={props.user} />
            </Row>
        </Container>
    );
}

export default Header;
